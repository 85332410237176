<template>
  <div class="contacts-page dark-bg">
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <bread-crumb></bread-crumb>

    <div class="contacts container container__mini2" v-if="data">
      <div class="contacts__descr" v-html="data.description"></div>

      <h2 class="contacts__title">{{ data.title }}</h2>

      <div class="contacts__wrap">
        <div class="contacts__img">
          <div class="contacts__img-wrap">
            <a
              href="https://www.google.com.ua/maps/place/iIT+Trading/@50.4003037,30.5041308,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4c8ccbc43aaab:0xa070c4cae083379b!8m2!3d50.4003037!4d30.5063195?hl=ru"
              rel="noreferrer"
              target="_blank"
            >
              <div
                class="contacts__img-map"
                v-if="
                  data.google_map_api_key !== null && data.google_map_api_key
                "
              >
                <GoogleMap
                  :api-key="data.google_map_api_key"
                  :center="data.coordinates.data"
                  :zoom="15"
                  class="contacts__img-iframe"
                >
                  <Marker :options="{ position: data.coordinates.data }" />
                </GoogleMap>
              </div>
              <div v-else>
                <v-lazy-image
                  :src="url + data.image_map"
                  rel="preload"
                  width="713"
                  height="537"
                  alt="map"
                />
              </div>
            </a>
          </div>
        </div>

        <div class="contacts__content">
          <h3>{{ data.placeholder }}</h3>

          <a class="phone" href="tel:+380443399977">{{ data.tel }}</a>
          <a class="email" href="mailto:sales@iitt.com.ua">{{ data.email }}</a>
          <address>{{ data.position }}</address>
        </div>
      </div>
    </div>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb.vue")
);

import VLazyImage from "v-lazy-image";
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { GoogleMap, Marker } from "vue3-google-map";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("Contacts"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    GoogleMap,
    Marker,
    VLazyImage,
    HeroBlock,
    FormCommon,
    BreadCrumb,
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      image: "",
      icon: "",
      title: "",
      data: [],
      locale: this.$i18n.locale,
    };
  },
  methods: {
    async getContactPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/contact-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image ?? null;
        this.icon = response.data.data[0].icon[0].url ?? null;
        this.title = response.data.data[0].title ?? null;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  mounted() {
    this.getContactPage();
    this.getBanner();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.contacts {
  @include property("margin-bottom", 100, 30);
  color: #fff;
  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #f9b80e;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__wrap {
    display: grid;
    align-items: center;
    @include property("gap", 68, 20, true, 1366, 768);

    @media (min-width: 768px) {
      grid-template-columns: 1.67fr 1fr;
    }
  }

  &__img {
    @include property("margin-right", 20, 10, true, 1024, 768);

    &-wrap {
      position: relative;
      padding-bottom: 75.3%;
      z-index: 2;

      &::after {
        content: "";
        position: absolute;
        @include property("top", -20, -10, true, 1024, 768);
        @include property("left", 20, 10, true, 1024, 768);
        width: 100%;
        height: 100%;
        border: 1px solid #f9b80e;
        z-index: -1;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        z-index: 1;
      }
    }

    &-map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }

    &-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 767.98px) {
      order: -1;
    }

    h3,
    a,
    address {
      @include property("font-size", 24, 18);
      line-height: calc(31 / 24);
      letter-spacing: 0.48px;
    }

    address,
    a {
      position: relative;
      padding-left: 31px;

      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 21px;
        height: 28px;
        background: #fff;
        transition: all 0.3s linear;
      }
    }

    h3 {
      color: #f9b80e;
      margin-bottom: 0.9rem;
    }

    a {
      color: #fff;
      margin-bottom: 0.9rem;

      &.phone {
        &::before {
          mask-image: url("~@/assets/images/call.svg");
          mask-repeat: no-repeat;
          mask-size: 21px 21px;
          mask-position: center center;
        }
      }

      &.email {
        &::before {
          mask-image: url("~@/assets/images/email.svg");
          mask-repeat: no-repeat;
          mask-size: 21px 15px;
          mask-position: center center;
        }
      }

      @media (hover: hover) {
        &:hover {
          color: #f9b80e;

          &::before {
            background: #f9b80e;
          }
        }
      }
    }

    address {
      font-style: normal;

      &::before {
        mask-image: url("~@/assets/images/map.svg");
        mask-repeat: no-repeat;
        mask-size: 21px 28px;
        mask-position: center center;
      }
    }
  }
}
</style>
